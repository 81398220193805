import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import httpClient from "./httpClient";
import {ReactComponent as Link} from "../svgs/link.svg";
import {ReactComponent as OK} from "../svgs/V.svg";
import {ReactComponent as Delete} from "../svgs/X.svg";
import {PATH as PATH} from "../paths";


export const YandexSortComponent = ({token, doc, verify}) => {
    const [access, setAccess] = useState("")
    const [docID, setDocID] = useState(null)
    const [sort_, setSort_] = useState(false)
    const [isSorted, setIsSorted] = useState(false)
    const [yadFolder, setYadFolder] = useState(null)
    const [error, setError] = useState({"error_code": 0})

    useEffect(() => {
        setAccess(token)
    }, [token])

    useEffect(() => {
        setDocID(doc)
    }, [doc])

    const sorting = () => {
        if (sort_) {
            setSort_(false)
        } else {
            setSort_(true)
        }
    }

    const sortSubmit = async () => {
        try {
            setSort_(true)
            setIsSorted(false)
            setError({"error_code": 0})
            const res = await httpClient.get(`${PATH}/api/yad/sort?spreadsheet_id=${docID}`,{
                headers: {
                    Authorization: access
                }
            })
            setSort_(false)
            setIsSorted(true)
            setYadFolder(res['data']['details']["url"])
            console.log(res['data']['details'])
        } catch (e) {
            setSort_(false)
            if (e.response.status === 401) {
                verify()
            }
            if (e.response.status === 500) {
                setError({"error_code": 500})
            }
            setError(e.response.data)
            console.log(e.response.data)
        }
    }

    const navigate = useNavigate()
    if (!access) {
        return (
            <div className="docBlock block"></div>
        )
    } else if (!docID) {
        return (
            <div className="docBlock block"></div>
        )
    } else {
        return (
            <div className='yadSortedBlock'>
                {(! sort_) ? (
                    <div className='buttonWithSpinner'><button className="btnsHolder clearBtn linkHolder docLink" type="button" onClick={() => sortSubmit()}>Отсортировать фотографии</button>
                    </div>
                ) : (
                    <div className='buttonWithSpinner'><button className="btnsHolder clearBtn linkHolder docLink" type="button" disabled>Идет сортировка фотографий</button>
                        <div className='spinner'/>
                    </div>
                )}
                {(error["error_code"]) ? (
                    <div className='sortState'>
                        <div className='errorMsg errorYadFolder'>{error['details']['message']}</div>
                    </div>
                ) : (error["error_code"] === 500) ? (
                    <div className='sortState'>
                        <div className='errorMsg'>Попробуйте снова</div>
                    </div>
                ) : (isSorted) ? (
                    <div className='sortState'>
                        <div>Фотографии отсортированы</div>
                    </div>
                ) : (
                    <div className='sortState'>
                        <div className='errorMsg'></div>
                    </div>
                )}
            </div>
        )
    }
}