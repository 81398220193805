import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import httpClient from "./httpClient";
import {ReactComponent as OK} from "../svgs/V.svg";
import {ReactComponent as Link} from "../svgs/link.svg";
import {PATH as PATH} from "../paths";


export const YandexComponent = ({token, login_, verify}) => {
    const [access, setAccess] = useState("")
    const [yaLogin, setYaLogin] = useState(null)
    const [oauth, setOauth] = useState(null)
    const [code, setCode] = useState('')

    useEffect(() => {
        setAccess(token)
    }, [token])

    useEffect(() => {
        setYaLogin(login_)
    }, [login_])

    useEffect(() => {
    }, [yaLogin])

    const yaRequest = async () => {
        try {
            const res = await httpClient.get(`${PATH}/api/yad/request`, {
                headers: {
                    Authorization: access
                }
            })
            setOauth(res['data']['details']['url'])
        } catch (e) {
            if (e.response.status === 401) {
                verify()
            }
            console.log(e.response.data)
        }
    }

    const yaSubmit = async () => {
        try {
            const res = await httpClient.put(`${PATH}/api/yad/submit?code=${code}`, {}, {
                headers: {
                    Authorization: access
                }
            })
            setYaLogin(res['data']['details']['login'])
        } catch (e) {
            if (e.response.status === 401) {
                verify()
            }
            console.log(e.response.data)
        }
    }

    const yaDelete = async () => {
        try {
            const res = await httpClient.delete(`${PATH}/api/yad/delete`, {
                headers: {
                    Authorization: access
                }
            })
            setYaLogin(null)
            setOauth(null)
            setCode('')
        } catch (e) {
            if (e.response.status === 401) {
                verify()
            }
            console.log(e.response.data)
        }
    }

    const onCodeChange = (inputValue) => {
        setCode(inputValue)
    }

    const handleCodeChange = (e) => {
        onCodeChange(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    const navigate = useNavigate()
    if (!access) {
        return (
            <div></div>
        )
    } else if (!yaLogin) {
        if (!oauth) {
            return (
                <button className='btnsHolder clearBtn yadHolder' onClick={yaRequest}>Подключить Я.Диск</button>
            )
        } else {
            return (
                <div>
                    <form className="yadForm" onSubmit={handleSubmit}>
                        <input className="logText" type="text" required value={code} onChange={handleCodeChange} placeholder="Код"/>
                        <button className="btnsHolder clearBtn linkHolder" type="button" onClick={() => {window.open(oauth, '_blank')}}><Link /></button>
                        <button className="btnsHolder clearBtn okHolder" type="submit" onClick={yaSubmit} disabled={(!code)}><OK/></button>
                    </form>
                </div>
            )
        }
    } else {
        return (
            <button title='Яндекс.Диск' className='btnsHolder clearBtn yadHolder' onClick={yaDelete}>{yaLogin}</button>
    )
    }
}