import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import httpClient from "./httpClient";
import { ReactComponent as Logout } from '../svgs/logout.svg';
import {ReactComponent as User} from "../svgs/user.svg";
import {ReactComponent as Pick} from "../svgs/pick.svg";
import {YandexComponent} from "./YandexComponent";
import {getCookie} from "../cookies";
import {PATH as PATH} from "../paths";
import {PromoComponent} from "./PromoComponent";


export const ProfileComponent = ({token, verify}) => {
    const [access, setAccess] = useState("")
    const [user, setUser] = useState("")
    const [restart, setRestart] = useState(true)

    useEffect(() => {
        setAccess(token)
    }, [token])

    useEffect(() => {
    }, [user, restart])

    const handlerFunction = () => {
        if (restart) {
            setRestart(false)
        } else {
            setRestart(true)
        }
    }

    useEffect(() => {
        if (access) {
            fetch(`${PATH}/api/user/get`, {
                headers: {
                    Authorization: access
                }
            }).then(
                res => res.json()
            ).then(
                doc => {
                    setUser(doc)
                }
            )
        }
    }, [access])

    const logOutUser = async () => {
        try {
            let refresh = getCookie('refresh')
            const res = await httpClient.post(`${PATH}/api/user/logout`, {
                refresh
            }, {
                headers: {
                    Authorization: access
                }
            })
            document.location.href = '/login'
        } catch (e) {
            if (e.response.status === 401) {
                verify()
            }
            console.log(e.response.data)
        }
    }

    const navigate = useNavigate()
    if (!access) {
        return (
            <div className="userBlock block"></div>
        )
    } else if (!user) {
        return (
            <div className="userBlock block"></div>
        )
    } else {
        let userdata = user['details']
        console.log(userdata)
        return (
            <div className="userBlock block">
                <div className='col1'>
                    <div className='userHolder'><User/></div>
                </div>
                <div className='col2'>
                    <div className='row1'>
                        <div className="loginHolder">{userdata["login"]}</div>
                        {/*<div className="borderHolder"></div>
                        <div className="balanceHolder">Баланс: 99<div className='pickHolder'><Pick /></div></div>*/}
                    </div>
                    <div className="profileBtnHolder row2">
                        {/*<button className='logoutHolder clearBtn'>Подключить телеграм</button>*/}
                        <div className="btnsHolder clearBtn daysLeft">Осталось дней: {userdata["days_left"]}</div>
                        <YandexComponent token={access} login_={userdata['yandex_login']} verify={verify}/>
                        <PromoComponent token={access} verify={verify} handlerRender={handlerFunction}></PromoComponent>
                        <button className='btnsHolder clearBtn' onClick={logOutUser}>Выйти</button>
                    </div>
                </div>
            </div>
        )
    }
}