import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import httpClient from "../components/httpClient"
import {ReactComponent as Logo} from "../logo.svg";
import {getCookie, setCookie, deleteCookie} from "../cookies";
import {PATH as PATH} from "../paths";


export const LogoutPage = () => {

    useEffect(() => {
        let id = getCookie('id')
        let refresh = getCookie('refresh')
        if (refresh) {
            verifyToken(refresh)
        } else {
            document.location.href = '/login'
        }
    }, [])

    const verifyToken = async (token) => {
        try {
            console.log('refresh', token)
            const res = await httpClient.post(`${PATH}/api/token/verify`, {
                    token
                }
            )

            logOutUser(token)
        } catch (e) {
            window.location.href = '/login'
            }
    }

    const logOutUser = async (token) => {
        try {
            console.log('access', token)
            const res = await httpClient.post(`${PATH}/api/user/logout`, {
                    refresh: token
                }, {headers: {

                }}
            )
            deleteCookie('refresh')
            deleteCookie('id')
            window.location.href = '/login'
        } catch (e) {
            console.log(e)
            if (e.response.status !== 200) {
            }
        }
    }

    const navigate = useNavigate()
    if (false) {
        document.location.href = '/'
    } else {
        return (
            <div className="LogoutPage">
                logout
            </div>
        )
    }
}