import React from 'react'
import {Routes, Route, Navigate} from "react-router-dom"
import {MainPage} from "./pages/MainPage"
import {RegistrationPage} from "./pages/RegistrationPage";
import {LoginPage} from "./pages/LoginPage";
import {LogoutPage} from "./pages/LogoutPage";
import {ProfilePage} from "./pages/ProfilePage";


export const useRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<MainPage />} />
            <Route path='/profile/:uid' element={<ProfilePage />} />
            <Route path='/register' element={<RegistrationPage />} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/logout' element={<LogoutPage />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    )
}