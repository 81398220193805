import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import httpClient from "./httpClient";
import {ReactComponent as Link} from "../svgs/link.svg";
import {ReactComponent as OK} from "../svgs/V.svg";
import {ReactComponent as Delete} from "../svgs/X.svg";
import {ReactComponent as I} from "../svgs/i.svg";
import {DocIDComponent} from "./DocIDComponent";
import {PATH as PATH} from "../paths";


export const DocComponent = ({token, id, handler, handlerRender, verify}) => {
    const [access, setAccess] = useState("")
    const [name, setName] = useState("")
    const [docID, setDocID] = useState("0")
    const [doc, setDoc] = useState(null)
    const [columns, setColumns] = useState([])
    const [columnName, setColumnName] = useState("")
    const [render, setRender] = useState(0)
    const [creating, setCreating] = useState(false)
    const [error, setError] = useState({"error_code": 0})

    useEffect(() => {
        setAccess(token)
    }, [token])

    useEffect(() => {
        setDocID(id)
        setDoc(null)
    }, [id])

    useEffect(() => {
    }, [doc, docID])

    useEffect(() => {
        if ((access) && (docID !== '0') && (docID !== '-1')) {
            fetch(`${PATH}/api/doc/get?id=${docID}`, {
                headers: {
                    Authorization: access
                }
            }).then(
                res => res.json()
            ).then(
                doc => {
                    setDoc(doc)
                }
            )
        }
    }, [docID, render])

     const docCreate = async () => {
        try {
            setCreating(true)
            setError({"error_code": 0})
            const res = await httpClient.post(`${PATH}/api/doc/create`, {
                title: name,
                columns
            }, {
                headers: {
                    Authorization: access
                }
            })
            handler("0")
            setDocID("0")
            setName("")
            setColumns([])
            handlerRender()
            setCreating(false)
        } catch (e) {
            setError(e.response.data)
            if (e.response.status === 401) {
                verify()
            }
            console.log(e.response.data)
        }
    }

    const onNameChange = (inputValue) => {
        setName(inputValue)
    }

    const handleNameChange = (e) => {
        onNameChange(e.target.value)
    }

    const onColumnNameChange = (inputValue) => {
        setColumnName(inputValue)
    }

    const handleColumnNameChange = (e) => {
        onColumnNameChange(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
    }

     const addColumn = () => {
         let tmp = columns
         if ((!tmp.includes(columnName)) && (tmp.length < 5)) {
             tmp.push(columnName)
             setColumns(tmp)
         }
         setColumnName("")
     }

    let columnsList = []

    for (let i = 0; i < columns.length; i++) {
        columnsList.push(
            <div className="columnItem" key={i}>
                {columns[i]}
                <button className="btnsHolder clearBtn xHolder" onClick={() => deleteFromArray(i)}><Delete/></button>
            </div>
        )
    }

    const deleteFromArray = (index, colList=columnsList) => {
        let current_array = columns
        if (index > -1) {
            current_array.splice(index, 1)
        }
        setColumns(current_array)
        colList.length = 0
        if (render === 0) {
            setRender(1)
        } else {
            setRender(0)
        }
    }

    const navigate = useNavigate()
    if (!access) {
        return (
            <div className="docBlock block"></div>
        )
    } else if (docID === "0") {
        return (
            <div className="docBlock block"></div>
        )
    } else if (docID === "-1") {
        return (
            <div className="docBlock block">
                <div className="docHeader">Создать новый документ</div>
                <form className="docForm" onSubmit={handleSubmit}>
                    <input className="logText columnText" type="text" value={name} onChange={handleNameChange} placeholder="Название документа"/>
                    <div className='columnsHolder'>
                        <input className="logText" maxLength="16" type="text" value={columnName} onChange={handleColumnNameChange} placeholder="Название столбца"/>
                        <button className="btnsHolder clearBtn okHolder okColumn" type="submit" onClick={addColumn} disabled={(!columnName)}><OK/></button>
                        <div className="columnItem">Имя</div>
                        <div className="columnItem">Фамилия</div>
                        {columnsList}
                    </div>
                    {(creating) ? (
                        <input className="logBtn columnBtn" type="submit" disabled value="Идет создание документа"/>
                    ) : (
                        <input className="logBtn columnBtn" type="submit" onClick={docCreate} disabled={(!name)} value="Создать"/>
                    )}
                    {(error["error_code"]) ? (
                        <div className='errorMsg'>Не удалось создать документ. Попробуйте еще раз.</div>
                    ) : (
                        <div className='errorMsg'></div>
                    )}
                </form>
                <div className='tipText'><div className='iHolder'><I/></div>После создания документа не изменяйте название документа, название листа и названия заблокированных ячеек(названия столбцов).</div>
            </div>
        )
    } else if (!doc) {
        return (
            <div className="docBlock block"></div>
        )
    } else {
        let doc_ = doc['details']
        console.log(doc_)
        /*<div className="docBlock block">
                <div className="docHeader">{doc_['spreadsheet_name']}</div>
                <div className="docDataBlock">
                    {(doc_["yandex_url"]) ? (
                        <div>Папка с фотографиями: {doc_["yandex_url"]}</div>
                    ) : (
                        <div>Укажите папку с фотографиями</div>
                    )}
                </div>
            </div>*/
        return (
            <DocIDComponent token={token} data={doc_} verify={verify}/>
        )
    }
}