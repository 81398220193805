import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import httpClient from "../components/httpClient"
import {getCookie, setCookie, deleteCookie} from "../cookies";
import {ReactComponent as Logov} from "../svgs/pickpic_logo_v.svg";
import {PATH as PATH} from "../paths";


export const LoginPage = () => {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [userID, setUserID] = useState(null)
    const [error, setError] = useState({"error_code": 0})
    const [access, setAccess] = useState("")
    const [refresh, setRefresh] = useState("")

    useEffect(() => {
        let id = getCookie('id')
        let refresh = getCookie('refresh')
        if (refresh) {
            verifyToken(refresh)
        }
    }, [])

    const verifyToken = async (token) => {
        try {
            const res = await httpClient.post(`${PATH}/api/token/verify`, {
                    token
                }
            )
            refreshToken(token)
        } catch (e) {
            console.log(e.response.data)
            }
    }

    const refreshToken = async (token) => {
        try {
            const res = await httpClient.post(`${PATH}/api/token/refresh`, {
                    refresh: token
                }
            )
            let id = getCookie('id')
            let access = res.data["access"]
            window.location.href = `/profile/${id}`
        } catch (e) {
            console.log(e.response.data)
            }
    }

    const logInUser = async () => {
        try {
            const res = await httpClient.post(`${PATH}/api/user/login`, {
                    login,
                    password
                }
            )
            setLogin('')
            setPassword('')
            let id = res['data']['details']['id']
            let access = res['data']['details']['tokens']['access']
            let refresh = res['data']['details']['tokens']['refresh']
            setCookie('id', id, {'max-age:': 604800})
            setCookie('refresh', refresh, {'max-age:': 604800})
            document.location.href = `/profile/${id}`
        } catch (e) {
            setError(e.response.data)
            setPassword('')
            setLogin('')
            if (e.response.status !== 200) {
                console.log(e.response.data)
            }
        }
    }

    const onLoginChange = (inputValue) => {
        setLogin(inputValue)
    }

    const handleLoginChange = (e) => {
        onLoginChange(e.target.value)
    }

    const onPasswordChange = (inputValue) => {
        setPassword(inputValue)
    }

    const handlePasswordChange = (e) => {
        onPasswordChange(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    const navigate = useNavigate()
    if (false) {
        document.location.href = '/'
    } else {
        return (
            <div className="LoginPage">
                <div className="loginFormHolder block loginFormHeight">
                    <div className="logoHolder">pickpic</div>
                    <div className="textHolder">Личный кабинет</div>
                    <form className="loginForm loginGap" onSubmit={handleSubmit}>
                        <input className="logText" type="text" required value={login} onChange={handleLoginChange} placeholder="Логин"/>
                        <input className="logText" type="password" required value={password} onChange={handlePasswordChange} placeholder="Пароль"/>
                        {(error["error_code"]) ? (
                            <div className='errorMsg'>Неверно указан логин или пароль</div>
                        ) : (
                            <div className='errorMsg'></div>
                        )}
                        <input className="logBtn" type="submit" onClick={logInUser} disabled={(!password) || (!login)} value="Войти"/>
                        <a href='/register' className='goToReg logBtn'>Еще нет аккаунта?</a>
                    </form>
                </div>
            </div>
        )
    }
}