import React, {useEffect, useState} from 'react'
import {Navigate, useNavigate} from 'react-router-dom';
import httpClient from "../components/httpClient";
import {getCookie} from "../cookies";
import {ReactComponent as I} from "../svgs/i.svg";
import {PATH as PATH} from "../paths";


export const RegistrationPage = () => {
    const [email, setEmail] = useState('')
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [promo, setPromo] = useState('')
    const [userID, setUserID] = useState(null)
    const [error, setError] = useState({"error_code": 0})
    const [access, setAccess] = useState('')

    useEffect(() => {
        let id = getCookie('id')
        let refresh = getCookie('refresh')
        if (refresh) {
            verifyToken(refresh)
        }
    }, [])

    const verifyToken = async (token) => {
        try {
            const res = await httpClient.post(`${PATH}/api/token/verify`, {
                    token
                }
            )
            refreshToken(token)
            //window.location.href = '/'
        } catch (e) {
            console.log(e.response.data)
            //return e.response.data
            }
    }

    const refreshToken = async (token) => {
        try {
            const res = await httpClient.post(`${PATH}/api/token/refresh`, {
                    refresh: token
                }
            )
            let id = getCookie('id')
            let access = res.data["access"]
            console.log(access)
            window.location.href = `/profile/${id}`
        } catch (e) {
            console.log(e.response.data)
            }
    }

    const registerUser = async () => {
        try {
            const res = await httpClient.post(`${PATH}/api/user/register`, {
                login,
                email,
                password,
                promo
            })

            setEmail('')
            setPassword('')
            setLogin('')
            setPromo('')
            window.location.href = '/'
        } catch (e) {
            setEmail('')
            setPassword('')
            setPromo('')
            setLogin('')
            setError(e.response.data)
            if (e.response.status !== 200) {
                console.log(e.response.data)
            }
        }
    }

    const onEmailChange = (inputValue) => {
        setEmail(inputValue)
    }

    const onPasswordChange = (inputValue) => {
        setPassword(inputValue)
    }

    const onPromoChange = (inputValue) => {
        setPromo(inputValue)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    const handleEmailChange = (e) => {
        onEmailChange(e.target.value)
    }

    const handlePasswordChange = (e) => {
        onPasswordChange(e.target.value)
    }

    const handlePromoChange = (e) => {
        onPromoChange(e.target.value)
    }

    const onLoginChange = (inputValue) => {
        setLogin(inputValue)
    }

    const handleLoginChange = (e) => {
        onLoginChange(e.target.value)
    }

    return (
        <div className="LoginPage">
            <div className="loginFormHolder block">
                <div className="logoHolder">pickpic</div>
                <div className="textHolder">Регистрация</div>
                <form className="loginForm" onSubmit={handleSubmit}>
                    <input className="logText" type="text" required value={login} onChange={handleLoginChange} placeholder="Логин"/>
                    <input className="logText" type="text" required value={email} onChange={handleEmailChange} placeholder="Почта"/>
                    <input className="logText" type="password" required value={password} onChange={handlePasswordChange} placeholder="Пароль"/>
                    <input className="logText" type="text" required value={promo} onChange={handlePromoChange} placeholder="Код"/>
                    {(error["error_code"]) ? (
                        <div className='errorMsg'>{error['details']['message']}</div>
                    ) : (
                        <div className='errorMsg'></div>
                    )}
                    <input className="logBtn" type="submit" onClick={registerUser} disabled={(!password) || (!login) || (!promo) || (!email)} value="Зарегистрироваться"/>
                    <a href='/login' className='goToReg logBtn'>Уже есть аккаунт?</a>
                </form>
            </div>
            <div className='tipText registerTip'><div className='iHolder'><I/></div>Используйте свою реальную GMail почту, на неё будут выдаваться права на администрирование документов.</div>
        </div>
    )
}