import React, {useEffect, useState} from 'react'
import {ReactComponent as Logoh} from "../svgs/pickpic_logo_horizontal.svg";
import {ReactComponent as Logov} from "../svgs/pickpic_logo.svg";

export const MainPage = () => {
    document.location.href = '/login'
    return (
        <div className="MainPage">

        </div>
    )
}
