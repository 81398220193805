import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import httpClient from "./httpClient";
import {ReactComponent as Link} from "../svgs/link.svg";
import {ReactComponent as OK} from "../svgs/V.svg";
import {ReactComponent as Delete} from "../svgs/X.svg";
import {YandexSortComponent} from "./YandexSortComponent";
import {ReactComponent as I} from "../svgs/i.svg";
import {PATH as PATH} from "../paths";


export const DocIDComponent = ({token, data, verify}) => {
    const [access, setAccess] = useState("")
    const [doc, setDoc] = useState(null)
    const [folder, setFolder] = useState(true)
    const [yadURL, setYadURL] = useState("")
    const [yadURLPretty, setYadURLPretty] = useState("")
    const [error, setError] = useState({"error_code": 0})
    const [errorYad, setErrorYad] = useState({"error_code": 0})

    useEffect(() => {
        setAccess(token)
    }, [token])

    useEffect(() => {
        setDoc(data)
        setYadURL(data['yandex_url'])
    }, [data])

    useEffect(() => {
        let pretty = yadURL.split("https://disk.yandex.ru/client/disk")[1]
        setYadURLPretty(pretty)
    }, [yadURL])

    const folderCreation = () => {
        if (folder) {
            setFolder(false)
        } else {
            setFolder(true)
        }
    }

    const folderSubmit = async () => {
        try {
            setErrorYad({"error_code": 0})
            const res = await httpClient.patch(`${PATH}/api/doc/change`, {
                yandex_url: yadURL,
                spreadsheet_id: doc['spreadsheet_id'],
            }, {
                headers: {
                    Authorization: access
                }
            })
            folderCreation()
        } catch (e) {
            if (e.response.status === 401) {
                verify()
            }
            setErrorYad(e.response.data)
            console.log(e.response.data)
        }
    }

    const onYadURLChange = (inputValue) => {
        setYadURL(inputValue)
    }

    const handleYadURLChange = (e) => {
        onYadURLChange(e.target.value)
    }

    const navigate = useNavigate()
    if (!access) {
        return (
            <div className="docBlock block"></div>
        )
    } else if (!doc) {
        return (
            <div className="docBlock block"></div>
        )
    } else {
        return (
            <div className="docBlock block">
                <div className="docHeader">
                    <div>{doc['spreadsheet_name']}</div>
                    <button className="btnsHolder clearBtn linkHolder docLink" type="button" onClick={() => {window.open(doc['doc_url'], '_blank')}}><Link /></button>
                </div>
                <div className="docDataBlock">
                    <div className="folderHolder">
                        <div className="folderHeaderText">Папка с фотографиями</div>
                        {(folder) ? (
                            <input className="folderText logText" defaultValue={yadURLPretty} disabled={(folder)} onChange={handleYadURLChange} placeholder={(folder) ? ('не указана') : ('https://disk.yandex.ru/client/disk/...')}/>
                        ) : (
                            <input className="folderText logText" defaultValue={yadURL} disabled={(folder)} onChange={handleYadURLChange} placeholder={(folder) ? ('не указана') : ('https://disk.yandex.ru/client/disk/...')}/>
                        )}
                        <div className='folderBtns'>
                            {(folder) ? (
                                <button className="btnsHolder clearBtn folderBtn" onClick={folderCreation}>Изменить</button>
                            ) : (
                                <button className="btnsHolder clearBtn folderBtn" onClick={folderSubmit} disabled={(!yadURL)}>Сохранить</button>
                            )}
                            {((yadURL) && (folder)) ? (
                                <button className="btnsHolder clearBtn folderBtn" onClick={() => {window.open(yadURL, '_blank')}}>Открыть</button>
                            ) : (
                                <button className="btnsHolder clearBtn folderBtn" disabled>Открыть</button>
                            )}
                        </div>
                        {(errorYad["error_code"]) ? (
                            <div className='errorMsg errorYadFolder'>Ссылка должна быть в формате "https://disk.yandex.ru/client/disk/..."</div>
                        ) : (
                            <div className='errorMsg'></div>
                        )}
                    </div>
                    <YandexSortComponent token={token} doc={doc['spreadsheet_id']} verify={verify}/>
                </div>
                <div className='tipText'><div className='iHolder'><I/></div>Перед сортировкой фотографий подключите Яндекс аккаунт, на котором лежат фотографии.</div>
            </div>
        )
    }
}