import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import httpClient from "./httpClient";
import { UpOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { ReactComponent as AtoZ } from '../svgs/AtoZ.svg';
import { ReactComponent as Calendar } from '../svgs/calendar.svg';
import { ReactComponent as Plus } from '../svgs/plus.svg';
import {PATH as PATH} from "../paths";



export const SearchComponent = ({token, handler, render, verify}) => {
    const [search, setSearch] = useState("")
    const [docs, setDocs] = useState(null)
    const [access, setAccess] = useState("")
    const [filter, setFilter] = useState("-created");

    useEffect(() => {
        setAccess(token)
    }, [token])

    useEffect(() => {
        if (access) {
            fetch(`${PATH}/api/doc/search?s=${search}&filter=${filter}`, {
                headers: {
                    Authorization: access
                }
            }).then(
                res => res.json()
            ).then(
                docs => {
                    setDocs(docs)
                }
            )
        }
    }, [access, filter, search, render])

    const onSearchChange = (inputValue) => {
        setSearch(inputValue)
    }

    const handleSearchChange = (e) => {
        onSearchChange(e.target.value)
    }

    const switchFilter = (ref) => {
        if (ref === 'filterCreated') {
            if (filter === '-created') {
                setFilter('created')
            } else if (filter === 'created') {
                setFilter('-created')
            } else {
                setFilter('-created')
            }
        } else if (ref === 'filterName') {
            if (filter === '-spreadsheet_name') {
                setFilter('spreadsheet_name')
            } else if (filter === 'spreadsheet_name') {
                setFilter('-spreadsheet_name')
            } else {
                setFilter('-spreadsheet_name')
            }
        }
    }

    const navigate = useNavigate()
    if (!access) {
        return (
            <div className="searchBlock block"></div>
        )
    } else if (!docs) {
        return (
            <div className="searchBlock block"></div>
        )
    } else {
        let items = docs['details']['docs']
        return (
            <div className="searchBlock block">
                <div className='docsInfoHolder'>
                    <input className="searchText" type="text" required value={search} onChange={handleSearchChange} placeholder="Поиск"/>
                    <div className="docsInfoBtnHolder">
                        <button className='sortHolder clearBtn' onClick={() => switchFilter('filterName')}><AtoZ /></button>
                        <button className='sortHolder clearBtn' onClick={() => switchFilter('filterCreated')}><Calendar /></button>
                        <button className='sortHolder clearBtn' onClick={() => handler("-1")}><Plus /></button>
                    </div>
                    {(filter === '-spreadsheet_name') ? (
                        <p className='dateP noselect'>Сортировка по названию <b>↓</b></p>
                    ) : (filter === 'spreadsheet_name') ? (
                        <p className='dateP noselect'>Сортировка по названию <b>↑</b></p>
                    ) : (filter === '-created') ? (
                        <p className='dateP noselect'>Сортировка по дате <b>↓</b></p>
                    ) : (filter === 'created') ? (
                        <p className='dateP noselect'>Сортировка по дате <b>↑</b></p>
                    ) : (
                        <p className='dateP noselect'></p>
                    )}
                </div>
                <div className='docsBtnHolderUpper'>
                    <div className='docsBtnHolder yScroll'>
                        {items.map((doc, index) =>
                            <div className="searchItem" key={index} onClick={() => handler(doc["spreadsheet_id"])}>
                                <p className="nameP">{doc["name"]}</p>
                                <p className="dateP" style={{"fontStyle":"italic"}}>{doc["created_at"]}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
    )
    }
}