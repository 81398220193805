import React, {useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import {SearchComponent} from "../components/SearchComponent";
import {getCookie} from "../cookies";
import httpClient from "../components/httpClient";
import {DocComponent} from "../components/DocComponent";
import {ProfileComponent} from "../components/ProfileComponent";
import {PATH as PATH} from "../paths";

export const ProfilePage = () => {
    let { uid } = useParams();
    const [access, setAccess] = useState("")
    const [docID, setDocID] = useState(null)
    const [render, setRender] = useState(false)

    useEffect(() => {
        let id = getCookie('id')
        let refresh = getCookie('refresh')
        if (refresh) {
            verifyToken(refresh)
        }
    }, [])

    useEffect(() => {
    }, [access, docID])

    const handlerFunction = (id) => {
        setDocID(id)
    }

    const handlerRender = () => {
        if (render) {
            setRender(false)
        } else {
            setRender(true)
        }
    }

    const verifyToken = async (token) => {
        try {
            const res = await httpClient.post(`${PATH}/api/token/verify`, {
                    token
                }
            )
            refreshToken(token)
            //window.location.href = '/'
        } catch (e) {
            console.log(e.response.data)
            window.location.href = '/login'
            //return e.response.data
            }
    }

    const refreshToken = async (token) => {
        try {
            const res = await httpClient.post(`${PATH}/api/token/refresh`, {
                    refresh: token
                }
            )
            let id = getCookie('id')
            setAccess(`pp ${res.data["access"]}`)
        } catch (e) {
            console.log(e.response.data)
            window.location.href = '/login'
            }
    }

    const verifyHandler = () => {
        try {
            console.log('verify')
            //let refresh = getCookie('refresh')
            //verifyToken(refresh)
            window.location.reload()
        } catch (e) {
            console.log(e.response.data)
            window.location.href = '/login'
            }
    }

    return (
            <div className="ProfilePage">
                <div className="headerHolder">
                    pickpic<i style={{"fontSize":"50%"}}>beta</i>
                </div>
                <div className='rowsHolder'>
                    <div className='row profileHolder'>
                        <ProfileComponent token={access} verify={verifyHandler}/>
                    </div>
                    <div className='row dataHolder'>
                        <SearchComponent token={access} handler={handlerFunction} render={render} verify={verifyHandler}/>
                        <DocComponent token={access} id={docID} handler={handlerFunction} handlerRender={handlerRender} verify={verifyHandler}/>
                    </div>
                </div>
            </div>
    )
}
