import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import httpClient from "./httpClient";
import {ReactComponent as OK} from "../svgs/V.svg";
import {ReactComponent as Link} from "../svgs/link.svg";
import {PATH as PATH} from "../paths";


export const PromoComponent = ({token, verify, handlerRender}) => {
    const [access, setAccess] = useState("")
    const [code, setCode] = useState('')

    useEffect(() => {
        setAccess(token)
    }, [token])

    const promoSubmit = async () => {
        try {
            const res = await httpClient.post(`${PATH}/api/user/update-promo`, {
                promo: code
            }, {
                headers: {
                    Authorization: access
                }
            })
            setCode('')
            handlerRender()
            window.location.reload()
        } catch (e) {
            if (e.response.status === 401) {
                verify()
            }
            console.log(e.response.data)
            setCode('')
        }
    }

    const onCodeChange = (inputValue) => {
        setCode(inputValue)
    }

    const handleCodeChange = (e) => {
        onCodeChange(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    const navigate = useNavigate()
    if (!access) {
        return (
            <div></div>
        )
    } else {
        return (
            <form className="promoForm" onSubmit={handleSubmit}>
                <input className="logText" maxLength="7" type="text" value={code} onChange={handleCodeChange}
                       placeholder="Промокод"/>
                <button className="btnsHolder clearBtn okHolder" type="submit" onClick={promoSubmit}
                        disabled={(!code)||(code.length<7)}><OK/></button>
            </form>
        )
    }
}